import * as React from "react";
import { IhomeState } from "./IhomeState";
import { IhomeProps } from "./IhomeProps";
import styles from "./home.module.scss";
import CreditScoreBanner from "../../components/credit-score-banner/credit-score-banner";
import WhyChooseUs from "../../components/why-choose-us/why-choose-us";

class home extends React.Component<IhomeProps, IhomeState> {
  constructor(props: IhomeProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`${styles.home}`}>
        <CreditScoreBanner></CreditScoreBanner>
        <WhyChooseUs></WhyChooseUs>
      </div>
    );
  }
}

export default home;
