import { FaChartLine, FaMoneyBillWave, FaUmbrella } from "react-icons/fa";
import styles from "./credit-score-banner.module.scss";
const CreditScoreBanner = () => {
  return (
    <div
      className={`container-fluid bg-light text-dark p-5 ${styles["credit-score-banner"]}`}
    >
      <div className="row align-items-center">
        <div className="col-md-8">
          <div className="d-flex align-items-center">
            <FaUmbrella size={32} className="me-3" />
            <FaMoneyBillWave size={32} className="me-3" />
            <FaChartLine size={32} className="me-3" />
            <div>
              <h4 className="mb-0">Know your Credit Score now!</h4>
              <p className="mb-0">Check for detailed report & insights!</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-md-end mt-3 mt-md-0">
          <button className="btn btn-secondary">Get Report</button>
        </div>
      </div>
    </div>
  );
};

export default CreditScoreBanner;
