import * as React from 'react';
import { IcontactState } from './IcontactState';
import { IcontactProps } from './IcontactProps';
import styles from './contact.module.scss';

class contact extends React.Component<IcontactProps, IcontactState> {
  constructor(props: IcontactProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`${styles.contact}`}>
        <h3>contact Component!</h3>
      </div>
    );
  }
}

export default contact;
