import { Link } from './types';
export type IRootContext = {
  name: string;
  menu: Array<Link>;
  home: {};
  footer: {};
  about: {};
};

export const rootContext: IRootContext = {
  name: 'Easy Financial Services',
  menu: [
    { label: 'Home', url: '/' },
    { label: 'Services', url: '/contact' },
    { label: 'About Us', url: '/about' },
  ],
  home: {
    data: 'Easy FS coming soon',
  },
  footer: {
    data: 'Easy FS coming soon',
  },
  about: {
    data: 'Easy FS coming soon',
  },
};
