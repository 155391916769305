import * as React from "react";
import { IappState } from "./IappState";
import { IappProps } from "./IappProps";
import styles from "./app.module.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Home from "./pages/home/home";
import { createContext } from "react";
import { rootContext } from "../root-context";
import { Header } from "./components/header/header";
import { Footer } from "./components/footer/footer";

export const AppDataContext = createContext(rootContext);

class app extends React.Component<IappProps, IappState> {
  static contextType = AppDataContext;
  constructor(props: IappProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <AppDataContext.Provider value={rootContext}>
        <BrowserRouter>
          <div className={`${styles.app}`}>
            <Header></Header>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer></Footer>
          </div>
        </BrowserRouter>
      </AppDataContext.Provider>
    );
  }
}

export default app;
