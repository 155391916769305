import { Container, Row, Col, Card } from "react-bootstrap";
import {
  FaPercent,
  FaClock,
  FaHandHoldingUsd,
  FaFileAlt,
  FaShieldAlt,
  FaHome,
} from "react-icons/fa";
import styles from "./why-choose-us.module.scss";
const WhyChooseUs = () => {
  return (
    <Container className={`container-fluid bg-light mt-5 ${styles['why-choose-us']}`}>
      <div className="text-center mb-5">
        <h2>Why choose Easy Finance?</h2>
        <hr
          style={{
            width: "90px",
            height: "5px",
            backgroundColor: "#FFC107",
            margin: "10px auto",
            border: "none",
          }}
        />
      </div>
      <Row className="g-4">
        <Col md={4}>
          <Card className="h-100 text-center">
            <Card.Body>
              <FaPercent size={50}  />
              <Card.Title className="mt-3">Lower Interest Rates</Card.Title>
              <Card.Text>
                Get loans for multiple purposes at lower interest rates to suit
                your needs.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 text-center">
            <Card.Body>
              <FaClock size={50}  />
              <Card.Title className="mt-3">
                Fast Processing and Disbursal
              </Card.Title>
              <Card.Text>
                Apply online, check your eligibility and get money directly in
                your bank in 10 minutes.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 text-center">
            <Card.Body>
              <FaHandHoldingUsd size={50}  />
              <Card.Title className="mt-3">Easy Repayment Options</Card.Title>
              <Card.Text>
                Repay the loan amount in easy EMI with flexible tenure options.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 text-center">
            <Card.Body>
              <FaFileAlt size={50}  />
              <Card.Title className="mt-3">100% Paperless</Card.Title>
              <Card.Text>
                No paperwork or physical documentation is required, and you can
                apply and get a personal loan completely online.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 text-center">
            <Card.Body>
              <FaShieldAlt size={50}  />
              <Card.Title className="mt-3">
                Safe, Secure and Transparent
              </Card.Title>
              <Card.Text>
                Our loan application process is fully secured and safe and there
                are no hidden charges.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 text-center">
            <Card.Body>
              <FaHome size={50}  />
              <Card.Title className="mt-3">Collateral Free</Card.Title>
              <Card.Text>
                No collateral is required to apply for our personal loans.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* This may be required later */}
      {/* <Row className="mt-5 text-center">
        <Col md={3}>
          <h4>OVER</h4>
          <h2>4 Crore</h2>
          <p>Loans Disbursed</p>
        </Col>
        <Col md={3}>
          <h4>OVER</h4>
          <h2>1.2 Crore</h2>
          <p>Loan Customers</p>
        </Col>
        <Col md={3}>
          <h4>OVER</h4>
          <h2>55 Lakh</h2>
          <p>Active Users</p>
        </Col>
        <Col md={3}>
          <h4>OVER</h4>
          <h2>5 Lakhs</h2>
          <p>Max. Loan Amount</p>
        </Col>
      </Row> */}
    </Container>
  );
};

export default WhyChooseUs;
