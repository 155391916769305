import { FaCoins } from "react-icons/fa";
import styles from "./logo.module.scss";
export const Logo = () => {
  return (
    <div className={styles.logo}>
      <div className={styles.circle}>
        <span className={styles.eee}>ई</span>
        <span className={styles.fff}>F</span>
      </div>
      <div className={styles.text}>
        <span className={styles.easy}>
          <span className={styles.eText}>Easy</span>
          <span className={styles.coin}>
            <FaCoins />
          </span>
        </span>
        <span className={styles.finance}>Finance</span>
      </div>
    </div>
  );
};
