import * as React from 'react';
import { IaboutState } from './IaboutState';
import { IaboutProps } from './IaboutProps';
import styles from './about.module.scss';
import { Footer } from '../../components/footer/footer';

class about extends React.Component<IaboutProps, IaboutState> {
  constructor(props: IaboutProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <div className={`${styles.about}`}>
        Easy FS coming soon
        <Footer></Footer>
      </div>
    );
  }
}

export default about;
