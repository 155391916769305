import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import styles from "./header.module.scss";
import { Logo } from "../logo/logo";
import {
  FaCoins,
  FaMoneyBill,
  FaBuilding,
  FaRupeeSign
} from "react-icons/fa";
export const Header = () => {
  return (
    <Navbar
      expand="lg"
      className={`justify-content-between p-0 ${styles.header}`}
    >
      <Container>
        <Navbar.Brand href="#home">
          <Logo></Logo>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ height: "110px" }}>
          <Nav className={`ms-auto ${styles["header-container"]}`}>
            <NavDropdown
              title="Loans"
              id="loans-dropdown"
              className={styles["header-menu-link"]}
            >
              <NavDropdown.Item
                href="#personal-loan"
                className={styles["header-menu-link-child"]}
              >
                Personal Loan <FaCoins />
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#salary-on-demand"
                className={styles["header-menu-link-child"]}
              >
                Salary on Demand <FaMoneyBill />
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#loan-against-property"
                className={styles["header-menu-link-child"]}
              >
                Loan against Property <FaBuilding />
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#business-loan"
                className={styles["header-menu-link-child"]}
              >
                Business Loan <FaRupeeSign />
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="EMI Calculators"
              id="calculators-dropdown"
              className={styles["header-menu-link"]}
            >
              <NavDropdown.Item
                href="#emi-calculator"
                className={styles["header-menu-link-child"]}
              >
                Personal Loan EMI Calculator
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#business-loan-calculator"
                className={styles["header-menu-link-child"]}
              >
                Business Loan EMI Calculator
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#home-loan-calculator"
                className={styles["header-menu-link-child"]}
              >
                Home Loan EMI Calculator
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              href="#credit-report"
              className={styles["header-menu-link"]}
            >
              Credit Report
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
