import { Container, Row, Col, Nav } from "react-bootstrap";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaPhone,
  FaQuestionCircle,
  FaBook,
} from "react-icons/fa";
import styles from "./footer.module.scss";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <Row className="mb-4">
          <Col md={3}>
            <h5 className={styles["section-title"]}>Easy Finance</h5>
            <Nav className="flex-column">
              <Nav.Link href="#contact" className={styles["footer-link"]}>
                <FaPhone /> Contact Us
              </Nav.Link>
              <Nav.Link href="#faq" className={styles["footer-link"]}>
                <FaQuestionCircle /> FAQ
              </Nav.Link>
              <Nav.Link
                href="#responsible-lending"
                className={styles["footer-link"]}
              >
                <FaBook /> Responsible Lending
              </Nav.Link>
            </Nav>
          </Col>
          <Col md={3}>
            <h5 className={styles["section-title"]}>Services</h5>
            <Nav className="flex-column">
              <Nav.Link href="#flexi-loan" className={styles["footer-link"]}>
                Flexi Personal Loan
              </Nav.Link>
              <Nav.Link
                href="#self-employed-loan"
                className={styles["footer-link"]}
              >
                Personal Loan for Self-Employed
              </Nav.Link>
              <Nav.Link href="#salaried-loan" className={styles["footer-link"]}>
                Personal Loan for Salaried
              </Nav.Link>
              <Nav.Link
                href="#loan-against-property"
                className={styles["footer-link"]}
              >
                Loan Against Property
              </Nav.Link>
              <Nav.Link href="#business-loan" className={styles["footer-link"]}>
                Business Loan
              </Nav.Link>
            </Nav>
          </Col>
          <Col md={3}>
            <h5 className={styles["section-title"]}>Our Company</h5>
            <Nav className="flex-column">
              <Nav.Link href="#about-us" className={styles["footer-link"]}>
                About Us
              </Nav.Link>
              <Nav.Link href="#partner" className={styles["footer-link"]}>
                Partner with us
              </Nav.Link>
              <Nav.Link
                href="#digital-lending"
                className={styles["footer-link"]}
              >
                Digital Lending Partners
              </Nav.Link>
              <Nav.Link href="#lead-partners" className={styles["footer-link"]}>
                Digital Lead Partners
              </Nav.Link>
            </Nav>
          </Col>
          <Col md={3}>
            <h5 className={styles["section-title"]}>Legal</h5>
            <Nav className="flex-column">
              <Nav.Link href="#terms" className={styles["footer-link"]}>
                Terms and Conditions
              </Nav.Link>
              <Nav.Link href="#privacy" className={styles["footer-link"]}>
                Privacy Policy
              </Nav.Link>
              <Nav.Link href="#grievance" className={styles["footer-link"]}>
                Grievance Redressal
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className={styles['footer-social']}>
            <Nav.Link href="#facebook" className={styles["footer-link"]}>
              <FaFacebookF />
            </Nav.Link>
            <Nav.Link href="#linkedin" className={styles["footer-link"]}>
              <FaLinkedinIn />
            </Nav.Link>
            <Nav.Link href="#twitter" className={styles["footer-link"]}>
              <FaTwitter />
            </Nav.Link>
            <Nav.Link href="#youtube" className={styles["footer-link"]}>
              <FaYoutube />
            </Nav.Link>
            <Nav.Link href="#instagram" className={styles["footer-link"]}>
              <FaInstagram />
            </Nav.Link>
          </Col>
        </Row>
        <Row className="mt-4">
        <p className={styles["footer-legal"]}>Copyright @ 2024 Easy Financial Services Private Limited<br/>CIN: U64444WB2013PTC211120<br/>All Rights Reserved</p>
        </Row>
      </Container>
    </footer>
  );
};
